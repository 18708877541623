.header{
    min-height: 9.9vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000014;
    opacity: 1;
    position: fixed;
    width: 100%;
    z-index: 1000;
    padding: 0 8%;
}

.logo{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.logo img {
    width: auto;
    height: 60px;
}

.logo h5{
    font-size: 36px;
    font-weight: 100;
    padding: 0;
    margin: 0;
    margin-left: 0.5em;
}